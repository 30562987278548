.App {
  text-align: center;
}

.linkColor{
    color:white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img.skillImg {
    width: 60%;
}

img.projectImg {
    width: 90%;
}

.leetCodeStatBox {
    width: 100%;
}

.ToolsBox {
    background-color: #292a2b;
    width: 78%;
    border-radius: 10px;
}

img.leetCodeStat {
    width: 80%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2d313a;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  padding-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-exp {
    background-color: #45748f;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

.App-edu {
    background-color: #458f61;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

.App-Skills {
    background-color: #8f8845;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
    padding-bottom: 100px;
}

.App-lang {
    background-color: #8f8845;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
    padding-bottom: 100px;
}

.App-project {
    background-color: #121212;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
    padding-bottom: 100px;
}

.profilePic {
    max-width: 45%;
    margin: 0 auto;
    padding-bottom: 8px;
    margin-top: 30px;
}

img.pic {
    border-radius: 50%;
    max-width: 50%;
    background-color: #65c9ff;
    max-height: none;
    width: auto;
    height: auto;
    vertical-align: middle;
    -webkit-box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 7%);
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 7%);
}


.BottomNavigation {
    z-index: 100;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}


@media only screen and (max-width: 600px) {
  .BottomNavigation {
      font-size: 0;
  }
}



@media only screen and (max-width: 800px){
  .BottomNavigation{
      font-size: 0;
      flex-wrap: wrap;
      height: 100px;
  }

    .BottomNavigation button{
      min-width: 30%;
  }
}

.desc {
    width: 80%;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 40px;
    line-height: 2em;
}