body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td.left {
    text-align: left;
    padding: 5px;
}

td.right {
    text-align: right;
    padding: 5px;
}

table.summary {
  border-collapse: collapse;
  margin: 0em 1em 2em 1em;
  width: 80%;
}

tr{
border-bottom: 1pt solid #ffffff;
}